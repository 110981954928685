<template>
	<div
		:class="[
			isCheckout ? 'min-h-32' : 'min-h-45',
			'flex-col py-2 px-0 md:flex md:flex-row'
		]"
	>
		<section class="w-full h-full md:flex">
			<div class="flex-1">
				<h1
					:class="[
						'text-lg font-bold mb-0 pr-28 md:max-w-sm min-h-8 items-center flex w-full',
						{ 'w-11/12' : isMap }
					]"
					tabindex="0"
				>
					{{ businessName }}
				</h1>

				<section class="flex items-center min-h-8">
					<StarRating
						v-if="isMounted"
						:read-only="true"
						:rating="rating"
						:increment="0.1"
						:star-size="16"
						:inline="true"
						text-class="mt-1 mr-1"
						class="font-bold"
						tabindex="0"
					/>
					<div
						v-if="hasReviews"
						class="mt-0.5 flex items-center font-bold text-gray-500"
						tabindex="0"
					>
						(<span class="pt-0.5">
							{{ business.reviewCount }}
						</span>)
					</div>
				</section>

				<section
					v-if="!isCheckout"
					:class="[{ 'md:flex md:h-8 items-center': !isMap }]"
				>
					<div
						:class="[
							'flex items-center min-h-8 text-sm',
							{'md:mr-6': !isMap }
						]"
						tabindex="0"
					>
						<img
							:src="businessIcon"
							class="mr-2"
							width="16"
							height="16"
							alt="Business Type"
						>
						<span class="capitalize">
							{{ business.businessType }}
						</span>
					</div>
					<div
						v-if="hasPhone"
						class="flex items-center text-sm min-h-8"
					>
						<a
							:href="`tel:${phoneRaw}`"
							class="flex items-center text-black"
							target="_blank"
						>
							<img
								src="@/assets/icons/phone.svg"
								class="mr-2"
								width="16"
								height="16"
								alt="Phone"
							>
							{{ formattedPhone }}
						</a>
					</div>
					<div
						v-else
						class="flex items-center text-sm min-h-8"
					>
						<img
							src="@/assets/icons/phone.svg"
							class="mr-2"
							width="16"
							height="16"
							alt="Phone"
						>
						-
					</div>
				</section>

				<section class="flex items-center w-full min-h-8">
					<template v-if="hasDirections">
						<a
							v-if="showDirectionsLink"
							:href="business.directionsLink"
							class="flex items-center text-sm text-black"
							target="_blank"
						>
							<img
								src="@/assets/icons/pin.svg"
								class="mr-2"
								:width="16"
								:height="16"
								alt="Address"
							>
							<span :class="isMap ? 'flex line-clamp-2 w-1/2' : 'w-auto'">
								{{ address }}
							</span>
						</a>
						<div
							v-else
							class="flex items-center text-sm text-black"
						>
							<img
								src="@/assets/icons/pin.svg"
								class="mr-2"
								width="16"
								height="16"
								alt="Address"
							>
							<span class="truncate">
								{{ address }}
							</span>
						</div>
					</template>
				</section>

				<section
					v-if="!isCheckout"
					class="min-h-8"
				>
					<ClientOnly>
						<BusinessHours
							v-if="hasHours && !isCheckout"
							:hours="businessHours"
							:is-open="!!business.isOpen"
						/>
					</ClientOnly>
				</section>
				<section
					v-if="showChainListing"
					class="flex items-center min-h-8"
				>
					<router-link
						:to="chainUrlObject"
						class="text-sm font-bold text-green-500 transition-all duration-100 ease-in-out hover:text-purple-500 md:hover:underline md:hover:underline-offset-2"
					>
						View all <span class="uppercase">
							{{ chainName }}
						</span> dispensaries
					</router-link>
				</section>
			</div>
		</section>
		<section
			v-if="!isCheckout && !isMap"
			:class="[
				{'space-x-2': isXs},
				'h-10 pt-2 md:pt-0 md:ml-auto self-center flex md:flex-col xl:flex-row md:space-y-3 xl:space-y-0 items-center justify-between space-x-4 md:justify-end'
			]"
		>
			<!-- #TODO: to be added back when api supports pickup/delivery -->
			<!-- <WwLabeledSwitch
				ref="order-logistics-switch"
				:options="orderLogisticsOptions"
				:width="isXs ? 10 : 11"
				:initial-option="activeOrderLogisticsOption"
				button-classes="shadow-sm group-hover:shadow"
				@selection-changed="updateOrderLogistics"
			/> -->
			<WwDropdown
				v-if="showIndication"
				:menu-items="menuItems"
				button-id="med-rec-toggle-button"
				:content-width="isXs ? 'w-32' : 'w-35'"
				content-classes="border border-gray-300"
				:force-open-position="dropdownPosition"
				@menu-clicked="handleMenuClick"
				@expanded="handleMenuExpanded"
			>
				<template #button="{ clicked }">
					<button
						id="menu-type"
						type="button"
						:class="[
							hasMedAndRec ? 'cursor-pointer hover:shadow focus:ring focus:ring-green-300 focus:border-none' : 'cursor-default',
							`h-9 flex items-center font-bold text-black bg-white border px-3 shadow-sm border-gray-300 rounded-full outline-none ${buttonWidth}`,
							'transition-all duration-100 ease-in']"
						aria-expanded="true"
						aria-haspopup="true"
						@click="handleDropDownClick(clicked)"
					>
						<span class="flex items-center w-full capitalize">
							<span
								:class="[
									{'mr-1': isXs},
									'text-gray-500 font-normal mr-2'
								]"
							>
								Menu:
							</span>
							<span>
								{{ isXs ? menuIndicationShort : menuIndication }}
							</span>
							<span
								v-if="hasMedAndRec"
								:class="[
									{'pl-2': isMobile},
									'ml-auto'
								]"
							>
								<ArrowTriangle
									:class="[
										'transition-all ease-in',
										{ 'transform rotate-180': isMenuExpanded }
									]"
								/>
							</span>
						</span>
					</button>
				</template>
			</WwDropdown>
		</section>
		<section
			:class="[
				'absolute top-12 right-3 shadow-sm text-center rounded self-start mt-0',
				{ 'md:relative md:mr-6 md:top-0 md:right-0 md:order-first': !isMap }
			]"
		>
			<Favorite
				v-if="showFavoriteComponent"
				class="absolute -top-2 -left-2 md:left-auto md:-right-2"
				:is-favorite="isFavorite"
				@set-favorite="setFavorite"
			/>
			<div :class="imageDimensions">
				<WwImage
					:src="businessImageFile"
					:width="imageWidth"
					:height="imageHeight"
					:alt="businessName + ' Thumbnail Image'"
					:fallback-image="DEFAULT_WW_GRAY_LOGO"
					:image-path="LISTING_IMAGES"
					:lazy="false"
					fetch-priority="high"
					class="w-26 h-26"
				/>
			</div>
		</section>
	</div>
</template>

<script async>
import ClientOnly from 'vue-client-only'
import { mapGetters, mapMutations } from 'vuex'

import ArrowTriangle from '@/components/icons/ArrowTriangle.vue'
import Favorite from '@/components/multiUse/Favorite.vue'
import WwImage from '@/components/multiUse/WwImage.vue'
// import WwLabeledSwitch from '@/components/multiUse/WwLabeledSwitch.vue'
import WwDropdown from '@/components/UI/WwDropdown.vue'
import { BUSINESS_TYPE_ICONS, INDICATION_TYPES, ORDER_LOGISTICS_TYPES } from '@/constants/business/index.js'
import { LISTING_IMAGES } from '@/constants/images/image-folder-paths.js'
import { LEFT, RIGHT } from '@/constants/positions.js'
import { DEFAULT_WW_GRAY_LOGO } from '@/constants/search/defaultImage.js'
import { FavoriteListingCreate, FavoriteListingDelete } from '@/gql/mutations/auth.gql'
import { GetAuth } from '@/gql/queries/auth.gql'
import isCheckoutPage from '@/utils/cart/is-checkout-page.js'
import { componentLevelGQLErrors } from '@/utils/error-handling.js'
import formatPhone from '@/utils/formatPhone.js'
import { formatAddress } from '@/utils/formatText.js'
import requireIcon from '@/utils/requireIcon.js'

const { PICKUP, DELIVERY } = ORDER_LOGISTICS_TYPES
const { RECREATIONAL, MEDICAL } = INDICATION_TYPES

export default {
	components: {
		StarRating: () => import('vue-star-rating'),
		WwImage,
		BusinessHours: () => import('./BusinessHours.vue'),
		WwDropdown,
		// WwLabeledSwitch,
		ArrowTriangle,
		Favorite,
		ClientOnly
	},
	props: {
		business: {
			type: Object,
			required: true
		},
		isMap: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isMounted: false,
			imageWidth: 200,
			imageHeight: 200,
			LISTING_IMAGES,
			DEFAULT_WW_GRAY_LOGO,
			orderLogisticsOption: PICKUP,
			orderLogisticsOptions: [
				PICKUP,
				DELIVERY
			],
			indication: '',
			isMenuExpanded: false,
			RIGHT,
			LEFT,
			menuItemOptions: [
				{
					text: 'Recreational',
					value: 'setRecreationalIndication',
					type: 'button',
					class: 'font-bold text-base px-3 py-2'
				},
				{
					text: 'Medical',
					value: 'setMedicalIndication',
					type: 'button',
					class: 'font-bold text-base px-3 py-2'
				}
			],
			interval: null
		}
	},
	computed: {
		...mapGetters('business', [ 'defaultIndication', 'hasMultipleIndications' ]),
		...mapGetters([ 'isMobile' ]),
		dropdownPosition() {
			return this.isMobile ? LEFT : RIGHT
		},
		showDirectionsLink() {
			return !this.isDeliveryOnly && this.hasDirectionLink
		},
		hasDirectionLink() {
			return !!this.business?.directionsLink?.length
		},
		mediaMatch() {
			return this.$store.state.mediaMatch
		},
		isXs() {
			return this.mediaMatch === 'xs'
		},
		activeOrderLogisticsOption() {
			return this.orderLogisticsOption === 'pickup' ? PICKUP : DELIVERY
		},
		menuItems() {
			if (this.hasMultipleIndications) return this.menuItemOptions
			if (this.business?.settings?.hasRecreational) return [ this.menuItemOptions[0] ]
			else if (this.business?.settings?.hasMedical) return [ this.menuItemOptions[1] ]
			return [ this.menuItemOptions[0] ] // default to recreational
		},
		menuIndication() {
			return this.$route.query?.indication || this.defaultIndication
		},
		menuIndicationShort() {
			if (this.menuIndication === MEDICAL.value) return 'Med'
			if (this.menuIndication === RECREATIONAL.value) return 'Rec'
			return ''
		},
		isDeliveryOnly() {
			return this.business?.settings?.hasDelivery && !this.business?.settings?.hasPickup
		},
		address() {
			const {
				street, city, state, zip
			} = this.business?.address

			const optionsForFormatter = { city, zip }
			if (!this.isDeliveryOnly) {
				optionsForFormatter.address = street
			}
			if (state?.short !== 'DC') {
				optionsForFormatter.state = state?.short
			}

			return formatAddress(optionsForFormatter)
		},
		phoneRaw() {
			return this.hasPhone && this.business?.phone?.replace(/([^0-9A-z])/g, '')
		},
		hasPhone() {
			return !!this.business?.phone
		},
		hasReviews() {
			return !!Number(this.business?.reviewCount)
		},
		businessName() {
			return this.business?.name || '-'
		},
		chainName() {
			return this.business?.chain?.[0]?.shortName || this.business?.chain?.[0]?.name || ''
		},
		businessImageFile() {
			return this.business?.logo?.fileName || 'default.png'
		},
		hasDirections() {
			return this.business?.directionsLink || false
		},
		businessIcon() {
			return requireIcon(BUSINESS_TYPE_ICONS[this.business?.businessType])
		},
		businessHours() {
			const businessHours = []
			if (this.business?.hours.length) {
				this.business.hours.forEach(day => {
					if (day.hoursType === 'business') {
						businessHours.push(day)
					}
				})
			}
			return businessHours.length ? businessHours : null
		},
		hasHours() {
			return !!this.business?.hours?.length
		},
		rating() {
			return parseFloat(this.business?.ratingOverall || 0)
		},
		isCheckout() {
			return isCheckoutPage(this.$route)
		},
		imageDimensions() {
			if (this.isMap) return 'overflow-hidden rounded-lg border h-36 w-36 shrink-0'
			return this.isCheckout
				? 'overflow-hidden rounded-lg border h-24 w-24'
				: 'overflow-hidden rounded-lg border md:max-h-48 md:w-48 h-28 md:min-h-48 w-28'
		},
		showFavoriteComponent() {
			return !this.isMap && !this.isCheckout && this.businessId
		},
		isFavorite() {
			return this.auth?.favoriteListingIds?.includes(this.businessId) || false
		},
		formattedPhone() {
			return formatPhone(this.business?.phone)
		},
		businessId() {
			return parseInt(this.business?.id) || ''
		},
		hasMedAndRec() {
			return this.menuItems?.length > 1
		},
		buttonWidth() {
			const isDesktopAndClickable = this.hasMedAndRec && !this.isMobile
			if (isDesktopAndClickable) return 'w-48'
			return 'w-auto'
		},
		showIndication() {
			const tabsWithoutIndication = [ 'about', 'deals', 'reviews', 'gallery' ]
			return !!this.defaultIndication && !tabsWithoutIndication.includes(this.$route.path.split('/').pop())
		},
		showChainListing() {
			return !this.isCheckout && !!this.$route.params?.chain
		},
		chainUrlObject() {
			return {
				name: 'chainListing',
				params: {
					chain: this.$route.params.chain
				}
			}
		}
	},
	watch: {
		indication() {
			this.setDefaultIndication(this.indication)
		}
	},
	apollo: {
		auth: {
			query: GetAuth,
			update(data) {
				if (data.auth) {
					this.$store.commit('auth/setAuth', { auth: data.auth })
					let favoriteListingIds = []
					if (data.auth.favoriteListings) {
						favoriteListingIds = data.auth.favoriteListings.map(listing => {
							return listing.id
						})
					}
					return {
						id: data.auth.id,
						favoriteListingIds
					}
				} else {
					return {}
				}
			},
			skip() {
				return this.isMap || !this.isMounted
			}
		}
	},
	mounted() {
		this.isMounted = true
	},
	beforeDestroy() {
		if (this.interval) {
			clearInterval(this.interval)
		}
	},
	methods: {
		...mapMutations('business', [ 'setDefaultIndication' ]),
		handleMenuClick(value) {
			this[value]()
		},
		handleMenuExpanded(isExpanded) {
			this.isMenuExpanded = isExpanded
		},
		updateOrderLogistics(logisticsType) {
			this.orderLogisticsOption = logisticsType
		},
		setMedicalIndication() {
			this.$router.push({ query: { ...this.$route.query, indication: 'medical' } })
			this.indication = 'medical'
		},
		setRecreationalIndication() {
			const newQueryObject = { ...this.$route.query }
			delete newQueryObject.indication
			this.$router.push({ query: { ...newQueryObject } })
			this.indication = 'recreational'
		},
		async setFavorite (favoriteSelected) {
			if (!this.auth?.id) {
				await this.$apollo.queries.auth.refetch()
			}
			if (favoriteSelected && !this.isFavorite) {
				try {
					this.$apollo.mutate({
						mutation: FavoriteListingCreate,
						variables: {
							userId: this.auth.id,
							listingId: this.businessId
						},
						update: (store) => {
							const data = store.readQuery({ query: GetAuth })
							data.auth.favoriteListings
								? data.auth.favoriteListings.push({ id: this.businessId, __typename: 'Listings' })
								: data.auth.favoriteListings = [ { id: this.businessId, __typename: 'Listings' } ]
							store.writeQuery({ query: GetAuth, data })
						}
					})
				} catch (error) {
					componentLevelGQLErrors(error)
				}
			} else if (!favoriteSelected) {
				try {
					this.$apollo.mutate({
						mutation: FavoriteListingDelete,
						variables: {
							userId: this.auth.id,
							listingId: this.businessId
						},
						update: (store) => {
							const data = store.readQuery({ query: GetAuth })
							data.auth.favoriteListings = data.auth.favoriteListings.filter(listing =>
								listing.id !== this.businessId
							)
							store.writeQuery({ query: GetAuth, data })
						}
					})
				} catch (error) {
					componentLevelGQLErrors(error)
				}
			}
		},
		handleDropDownClick(click) {
			if (this.hasMedAndRec) {
				return click()
			}
		}
	}
}
</script>
