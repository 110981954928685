<template>
	<button
		v-require-login="{ callback: toggleIcon, bindTo: 'click' }"
		class="flex items-center justify-center bg-white border border-gray-300 rounded-lg shadow-sm w-7 h-7 hover:shadow-lg"
	>
		<span class="relative block w-4 h-4">
			<HeartIconEmpty
				v-if="!favoriteSelected"
				class="absolute w-4 h-4 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
			/>
			<Transition name="bounce">
				<HeartIconRed v-if="favoriteSelected" />
			</Transition>
		</span>
	</button>
</template>

<script async>
import { mapGetters } from 'vuex'

import HeartIconEmpty from '@/components/icons/HeartIconEmpty.vue'
import HeartIconRed from '@/components/icons/HeartIconRed.vue'

export default {
	components: {
		HeartIconRed,
		HeartIconEmpty
	},
	props: {
		isFavorite: {
			type: Boolean,
			required: true
		}
	},
	emits: [ 'set-favorite' ],
	data() {
		return {
			favoriteSelected: false
		}
	},
	computed: {
		...mapGetters('auth', [ 'isLoggedIn' ])
	},
	watch: {
		isFavorite() {
			this.favoriteSelected = this.isFavorite
		},
		isLoggedIn() {
			if (!this.isLoggedIn) {
				this.favoriteSelected = false
			} else this.favoriteSelected = this.isFavorite
		}
	},
	mounted() {
		this.favoriteSelected = this.isFavorite
	},
	methods: {
		toggleIcon(data, loginNeeded) {
			if (loginNeeded) {
				this.favoriteSelected = true
				this.$emit('set-favorite', this.favoriteSelected)
			} else {
				this.favoriteSelected = !this.favoriteSelected
				this.$emit('set-favorite', this.favoriteSelected)
			}
		}
	}
}
</script>

<style lang="scss">
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
